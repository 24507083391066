import { t } from '../languages';
import "cesium/Source/Widgets/widgets.css";
const Cesium = require("cesium/Source/Cesium");
import * as turf from '@turf/turf'
import AmapMercatorTilingScheme from '@/utils/cesium/AmapMercatorTilingScheme'

const ufi3d = (function (global, factory) {
    "use strict";
    // if (typeof module === "object" && typeof module.exports === "object") {
    //   /* 
    //     适用于CommonJS和类似CommonJS的环境
    //     执行factory返回ufi3d并module.exports出去
    //    */
    //   module.exports = global.document ?
    //     factory(global, true) :
    //     function (w) {
    //       if (!w.document) {
    //         throw new Error("ufi3d requires a window with a document");
    //       }
    //       return factory(w);
    //     };
    //   // const ufi3d = factory(global);
    //   // export default ufi3d
    // } else {
    //   factory(global);
    // }
    return factory(global);
  })
  (typeof window !== "undefined" ? window : this, function (window, noGlobal) {
    "use strict";
    const ufi3d = new Object();

    /* 两点坐标返回距离 */
    ufi3d.spaceDistance = function (lastPosi, currPosi) {
      let distance = 0;
      let point1cartographic = Cesium.Cartographic.fromDegrees(lastPosi.longitude, lastPosi.latitude, lastPosi.altitude);
      let point2cartographic = Cesium.Cartographic.fromDegrees(currPosi.longitude, currPosi.latitude, currPosi.altitude);
      /**根据经纬度计算出距离**/
      let geodesic = new Cesium.EllipsoidGeodesic();
      geodesic.setEndPoints(point1cartographic, point2cartographic);
      let s = geodesic.surfaceDistance;
      //返回两点之间的距离
      s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
      distance = distance + s;
      return distance.toFixed(2);
    }

    function computeDistance(positions) {
      let distance = 0;
      for (let i = 0; i < positions.length - 1; i++) {
        distance += Cesium.Cartesian3.distance(positions[i], positions[i + 1]);
      }
      return distance;
    }

    function computeArea(viewer, positions) {
      var ellipsoid = viewer.scene.globe.ellipsoid;
      var lastArr = []
      lastArr.push(positions[0]) //人为设置结尾点为起点
      var newPositions = positions.concat(lastArr)
      const positionsArr = [];
      for (let i = 0; i < newPositions.length; i++) {
        let positionArr = []
        var cartographic = ellipsoid.cartesianToCartographic(newPositions[i]);
        // 转成经纬度存储
        positionArr.push(Cesium.Math.toDegrees(cartographic.longitude));
        positionArr.push(Cesium.Math.toDegrees(cartographic.latitude));
        positionsArr.push(positionArr)
      }
      // turf计算面积
      var polygon = turf.polygon([
        positionsArr
      ]);

      var area = turf.area(polygon);
      return area.toFixed(2);
    }

    /**
     * 初始化cesium
     * @param el 容器id
     * @param param 初始化cesium参数列表
     * @returns 三维地图容器viewer
     */
    ufi3d.initCesium = function (el, param) {
      let assignTarget = undefined;
      const source = {
        geocoder: false, // 地理位置查询定位控件(搜索框)
        homeButton: false, // 默认位置控件
        sceneModePicker: false, //切换2D和3D模式
        baseLayerPicker: false, // 三维地球底图切换控件
        navigationHelpButton: false, //操作三维地球帮助提示
        animation: false, // 控制视窗动画的播放速度控件
        timeline: false, // 时间进度条控件
        fullscreenButton: false, // 全屏控件
        selectionIndicator: false, // 显示点击位置聚焦显示框
        infoBox: false, //点击位置出现的信息框
        scene3DOnly: true, // 每个几何实例仅以3D渲染以节省GPU内存
        navigationInstructionsInitiallyVisible: false, //导航指令初始不可见
        showRenderLoopErrors: true, //报错是否弹出错误
        orderIndependentTranslucency: false, //设置背景透明
        vrButton: false, //VR控件
        shouldAnimate: true, // 是否显示动画
        shadows: false, // 是否打开阴影
        terrainShadows: false, // 是否打开地形阴影
      }


      // 账户token
      Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ZDc1NTgwZC02OGEwLTQ4YmMtOTBkYi0wZWJiODllZGZjYmUiLCJpZCI6NjIwNTksImlhdCI6MTYyNjY4Mjc5NX0.eUQ-RvJ_-wKUAl6OYYUsOBefoiRhKWa2vAI0FpxFwvk';
      if (param && Object.prototype.toString.call(param) === '[object Object]') {
        // 后面覆盖前面
        assignTarget = Object.assign(source, param)
      } else {
        assignTarget = source
      }
      const viewer = new Cesium.Viewer(el, assignTarget);

      // 深度检测
      viewer.scene.globe.depthTestAgainstTerrain = true;
      //是否开启抗锯齿
      viewer.scene.fxaa = true;
      viewer.scene.postProcessStages.fxaa.enabled = true;
      viewer._cesiumWidget._creditContainer.style.display = "none"; // 隐藏 LOGO 版权


      return viewer;
    }

    /* 相机flyTo */
    ufi3d.cameraFlyTo = function (viewer, option) {
      viewer.scene.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(option.lng, option.lat, option.alt),
        orientation: {
          heading: Cesium.Math.toRadians(option.heading),
          pitch: Cesium.Math.toRadians(option.pitch),
          roll: Cesium.Math.toRadians(option.roll),
        },
        duration: option.duration
      });
    }
    /* 视图flyTo */
    ufi3d.viewerFlyTo = function (viewer, option) {
      viewer.flyTo(option.entity, {
        offset: new Cesium.HeadingPitchRange(Cesium.Math.toRadians(option.heading), Cesium.Math.toRadians(option.pitch), option.range),
        duration: option.duration
      });
    }

    /* 修改实体position位置 */
    ufi3d.updateEntityPosi = function (entity, option) {
      entity.position = Cesium.Cartesian3.fromDegrees(
        option[0],
        option[1],
        option[2]
      );
    }

    /* 修改实体orientation方向 */
    ufi3d.updateEntityOri = function (entity, option) {
      entity.orientation = Cesium.Transforms.headingPitchRollQuaternion(
        entity.position._value,
        new Cesium.HeadingPitchRoll(
          Cesium.Math.toRadians(option.heading ? option.heading : 0),
          Cesium.Math.toRadians(option.pitch ? option.pitch : 0),
          Cesium.Math.toRadians(option.roll ? option.roll : 0)
        )
      )
    }

    ufi3d.EntityObject = {
      ModelEntity: class ModelEntity {
        constructor(viewer, option) {
          this.viewer = viewer;
          return this.addModel(option);
        }

        addModel(option) {
          console.log(option)
          const cartesian3 = Cesium.Cartesian3.fromDegrees(option.position[0], option.position[1], option.position[2]);
          const entity = this.viewer.entities.add({
            id: option.id ? option.id : '',
            name: "video",
            position: cartesian3,
            orientation: Cesium.Transforms.headingPitchRollQuaternion(
              cartesian3,
              new Cesium.HeadingPitchRoll(
                Cesium.Math.toRadians(option.orientation.heading ? option.orientation.heading : 0), // 设置这个属性即可（顺时针旋转的角度值）
                Cesium.Math.toRadians(option.orientation.pitch ? option.orientation.pitch : 0),
                Cesium.Math.toRadians(option.orientation.roll ? option.orientation.roll : 0)
              )
            ),
            label: {
              showBackground: true,
              backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
              backgroundPadding: new Cesium.Cartesian2(16, 10),
              font: option.label.font_size + 'px sans-serif',
              fillColor: option.label.color ? Cesium.Color.fromCssColorString(option.label.color) : Cesium.Color.WHITE,
              text: `${option.position[0]}\n${option.position[1]}\n${option.position[2]}`,
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              pixelOffset: new Cesium.Cartesian2(option.label.pixelOffsetX ? option.label.pixelOffsetX : 0, option.label.pixelOffsetY ? option.label.pixelOffsetY : 0), //偏移量
              distanceDisplayCondition: option.label.distanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.label.distanceDisplayCondition_near, option.label.distanceDisplayCondition_far) : undefined,
            },
            model: {
              uri: option.model.url ? option.model.url : '',
              clampAnimations: true,
              scale: option.model.scale ? option.model.scale : '',
            },
            billboard: {
              image: option.billboard.image ? option.billboard.image : '',
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              scale: option.billboard.scale ? option.billboard.scale : '',
              pixelOffset: new Cesium.Cartesian2(option.label.pixelOffsetX ? option.billboard.pixelOffsetX : 0, option.billboard.pixelOffsetY ? option.billboard.pixelOffsetY : 0), //偏移量
              distanceDisplayCondition: option.billboard.distanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.billboard.distanceDisplayCondition_near, option.billboard.distanceDisplayCondition_far) : undefined,
            },
          });
          return entity;
        }
      }
    }

    class Primitive {
      constructor(viewer, collectionType) {
        this.viewer = viewer;
        this.primitiveCollection = this.primitiveCollection || this.viewer.scene.primitives.add(collectionType);
        this.primitiveArr = [];
      }

      containsPrimitive(primitive) {
        return this.primitiveCollection.contains(primitive);
      }

      constainsPrimitiveById(primitiveId) {
        const index = this.primitiveArr.findIndex(item => {
          return item.id === primitiveId
        })
        return this.primitiveCollection.contains(this.primitiveArr[index]);
      }

      getPrimitiveByIndex(index) {
        return this.primitiveCollection.get(index);
      }

      getPrimitiveIndexById(primitiveId) {
        const index = this.primitiveArr.findIndex(item => {
          return item.id === primitiveId
        })
        return this.primitiveCollection.get(index);
      }

      removePrimitive(point) {
        const flag = this.primitiveCollection.remove(point);
        if (flag) {
          const index = this.primitiveArr.findIndex(item => {
            return item.id === point.id
          })
          this.primitiveArr.splice(index, 1)
        }
        return flag;
      }

      removePrimitiveById(pointId) {
        const index = this.primitiveArr.findIndex(item => {
          return item.id === pointId
        })
        const flag = this.primitiveCollection.remove(this.primitiveArr[index]);
        if (flag) this.primitiveArr.splice(index, 1)
        return flag;
      }

      removeAllPrimitive() {
        this.primitiveCollection.removeAll();
        this.primitiveArr = [];
      }

    }

    ufi3d.PrimitiveObject = {
      BillboardPrimitive: class BillboardPrimitive extends Primitive {
        constructor(viewer, option) {
          super(viewer, new Cesium.BillboardCollection());
          this.labelCollection = this.viewer.scene.primitives.add(new Cesium.LabelCollection())
          this.addPrimitive(option)
        }

        addPrimitive(option) {

          for (let i = 0; i < option.positions.length; i++) {
            const points = option.positions;
            if (points[i].text) {
              this.labelCollection.add({
                id: points[i].id ? points[i].id : undefined, //id
                position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2] ? points[i][2] : 0), //位置
                text: points[i].text, //文本
                scale: option.textScale ? option.textScale : 1.0, //缩放（1为正常）
                pixelOffset: new Cesium.Cartesian2(option.hOffset ? option.hOffset : 0, option.vOffset ? option.vOffset : 0), //偏移量
                distanceDisplayCondition: option.textDistanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.textDistanceDisplayCondition_near, option.textDistanceDisplayCondition_far) : undefined,
                zIndex: 2,
              })
            }
            const p = this.primitiveCollection.add({
              position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2] ? points[i][2] : 0), //位置
              image: option.picUrl, //图像
              scale: option.picScale ? option.picScale : 1.0, //缩放（1为正常）
              distanceDisplayCondition: option.picDistanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.picDistanceDisplayCondition_near, option.picDistanceDisplayCondition_far) : undefined,
              zIndex: 1,
            });
            this.primitiveArr.push(p)
          }
          return this.primitiveArr
        }

        removeAllPrimitive() {
          this.labelCollection.removeAll();
          super.removeAllPrimitive();
        }

      },
      PointPrimitive: class PointPrimitive extends Primitive {
        constructor(viewer, option) {
          super(viewer, new Cesium.PointPrimitiveCollection());
          this.labelCollection = this.viewer.scene.primitives.add(new Cesium.LabelCollection())
          return this.addPrimitive(option)
        }

        addPrimitive(option) {
          const points = option.positions;
          for (let i = 0; i < points.length; i++) {
            if (points[i].text) {
              this.labelCollection.add({
                position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2]), //位置
                text: points[i].text, //文本
                scale: 0.45, //缩放（1为正常）
                pixelOffset: new Cesium.Cartesian2(option.hOffset ? option.hOffset : 0, option.vOffset ? option.vOffset : 0), //偏移量
                distanceDisplayCondition: option.textDistanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.textDistanceDisplayCondition_near, option.textDistanceDisplayCondition_far) : undefined,
              })
            }
            const p = this.primitiveCollection.add({
              id: points[i].id, //id
              position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2]), //位置
              pixelSize: option.pSize ? option.pSize : 1, //尺寸
              color: option.color ? Cesium.Color.fromCssColorString(option.color) : Cesium.Color.WHITE, //填充颜色
              outlineColor: option.borderColor ? Cesium.Color.fromCssColorString(option.borderColor) : Cesium.Color.TRANSPARENT, //轮廓颜色
              outlineWidth: option.borderWidth ? option.borderWidth : 0.0, //轮廓宽度
              distanceDisplayCondition: option.pointDistanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.pointDistanceDisplayCondition_near, option.pointDistanceDisplayCondition_far) : undefined,
            });
            this.primitiveArr.push(p)
          }
          return this.primitiveArr
        }
      },
      /* 线 */
      LinePrimitive: class LinePrimitive extends Primitive {
        constructor(viewer, option) {
          super(viewer, new Cesium.PolylineCollection());
          this.addPolyline(option);
        }

        addPolyline(option) {
          const material = this.switchMaterial(option.lineType, option.colorOne, option.colorTwo);
          const p = this.primitiveCollection.add({
            id: option.id,
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(option.positions),
            width: option.lineWidth ? option.lineWidth : 10.0,
            // 轨迹线
            material: material,
            distanceDisplayCondition: option.distanceDisplayCondition ? new Cesium.DistanceDisplayCondition(option.distanceDisplayCondition_near, option.distanceDisplayCondition_far) : undefined,
          });
          this.primitiveArr.push(p)
          return this.primitiveArr
        }

        switchMaterial(type, colorOne, colorTwo) {
          const materialColorOne = Cesium.Color.fromCssColorString(colorOne);
          let materialColorTwo = Cesium.Color.WHITE;
          if (colorTwo) {
            materialColorTwo = Cesium.Color.fromCssColorString(colorTwo);
          }
          let material = undefined;
          switch (type) {
            // 发光材质
            case 'glow':
              material = Cesium.Material.fromType(Cesium.Material.PolylineGlowType, {
                glowPower: 0.1, //荧光效果，线周边发亮
                taperPower: 1, //控制一头线粗 一头线细，为1时线的两头粗细一样
                color: materialColorOne // 发光颜色
              })
              break;
              // 淡入淡出材质
            case 'fade':
              material = Cesium.Material.fromType(Cesium.Material.FadeType, {
                repeat: false,
                fadeInColor: materialColorOne,
                fadeOutColor: materialColorTwo.withAlpha(0),
                fadeDirection: {
                  x: true,
                  y: false
                }
              })
              break;
              // 边框线
            case 'outline':
              material = Cesium.Material.fromType(Cesium.Material.PolylineOutline, {
                color: materialColorOne,
                outlineColor: materialColorTwo,
                outlineWidth: 1
              })
              break;
              // 虚线
            case 'dash':
              material = new Cesium.Material({
                fabric: {
                  type: 'PolylineDash',
                  uniforms: {
                    color: materialColorOne
                  }
                }
              })
              break;
              // 斑马纹
            case 'zebra':
              material = new Cesium.Material({
                fabric: {
                  type: 'PolylineDash',
                  uniforms: {
                    color: materialColorOne,
                    gapColor: materialColorTwo
                  }
                }
              })
              break;
              // 尾部箭头
            case 'arrow':
              material = Cesium.Material.fromType(Cesium.Material.PolylineArrowType);
              break;
            default:
              material = new Cesium.Material({
                fabric: {
                  type: 'Color',
                  uniforms: {
                    color: materialColorOne
                  }
                }
              })
              break;
          }
          return material;
        }
      }
    }

    ufi3d.Graphic = {
      DivEmbed: class DivEmbed {
        constructor(viewer, option) {
          this.viewer = viewer;
          this.embed(option);
        }

        embed(option) {
          this.option = option;
          this.position = option.position;
          this.viewer.scene.preRender.addEventListener(() => {
            const position = Cesium.Cartesian3.fromDegrees(this.position[0], this.position[1], this.position[2]);
            const canvasPosition = this.viewer.scene.cartesianToCanvasCoordinates(
              position,
              new Cesium.Cartesian2()
            );
            if (Cesium.defined(canvasPosition)) {
              this.option.dom.style.transform = `translate(${canvasPosition.x - (this.option.offsetX ? this.option.offsetX : 0)}px, ${canvasPosition.y - (this.option.offsetY ? this.option.offsetY : 0)}px)`
              this.option.dom.style.transformOrigin = 'right top 0px';
            }
          });
        }

        unbind() {
          this.viewer.scene.preRender.removeEventListener(() => {
            const position = Cesium.Cartesian3.fromDegrees(this.position[0], this.position[1], this.position[2]);
            const canvasPosition = this.viewer.scene.cartesianToCanvasCoordinates(
              position,
              new Cesium.Cartesian2()
            );
            if (Cesium.defined(canvasPosition)) {
              this.option.dom.style.transform = `translate(${canvasPosition.x + (this.option.offsetX ? this.option.offsetX : 0)}px, ${canvasPosition.y + (this.option.offsetY ? this.option.offsetY : 0)}px)`
              this.option.dom.style.transformOrigin = 'right top 0px';
            }
          })
        }

        updatePosition(newPosition) {
          this.position = newPosition;
        }

      }
    }

    function changeHeight(tileset, height) {
      height = Number(height);
      if (isNaN(height)) {
        return;
      }
      var cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
      var surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
      var offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, height);
      var translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    }


    ufi3d.PointCloud = class PointCloud {
      constructor(viewer) {
        this.viewer = viewer;
      }

      addPointCloud(layarId, url, layarHeight, isZoomTo) {
        const tileset = this.viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
          url: url,
          maximumScreenSpaceError: 1,
          maximumMemoryUsage: 1024,
          dynamicScreenSpaceError: true,
          cullWithChildrenBounds: false,
          skipLevelOfDetail: true,
          preferLeaves: true,
        }));
        tileset.id = layarId;
        tileset.pointCloudShading.attenuation = true
        const viewer = this.viewer;
        tileset.readyPromise.then(function (tileset) {
          changeHeight(tileset, layarHeight)
          if (isZoomTo !== false) viewer.zoomTo(tileset);
        }).catch(function (error) {
          console.log(error);
        });
      }

      /* 切换显示点云 */
      showHidePointCloud(primitiveId, isShow) {
        this.viewer.scene.primitives._primitives.find((item) => {
          if (item.id === primitiveId) {
            item.show = isShow;
            // this.viewer.zoomTo(item);
          }
        })
      }

      /* 调整点云高度 */
      changePointCloudHei(primitiveId, height) {
        this.viewer.scene.primitives._primitives.find((item) => {
          if (item.id === primitiveId) {
            changeHeight(item, height)
          }
        })
      }

      /* 根据primitive定位点云 */
      jumpCloudPoint(primitiveId) {
        this.viewer.scene.primitives._primitives.find((item) => {
          if (item.id === primitiveId) {
            this.viewer.zoomTo(item);
          }
        })
      }
    }

    /**
     * @description: 检查网址可用性
     * @param {*} url   地址
     * @param {*} succCall   成功回调
     * @param {*} errorCall   失败回调
     * @return {*}  undefined
     */
    function checkWebsiteAvailability(url, succCall, errorCall) {
      fetch(url)
        .then((response) => {
          if (response.ok) {
            succCall()
          } else {
            errorCall()
          }
        })
        .catch(() => {
          errorCall()
        })
    }

    /* 自定义影像图片 */
    ufi3d.CustomImagery = class CustomImagery {
      constructor(viewer) {
        this.viewer = viewer;
        this.providerObj = {};
      }

      addImagery(id, url, metaInfo, isZoomTo) {
        const boundsInfo = JSON.parse(metaInfo).bounds;
        const imageryProvider = new Cesium.UrlTemplateImageryProvider({
          url: url,
          tilingScheme: new Cesium.WebMercatorTilingScheme(),
          rectangle: Cesium.Rectangle.fromDegrees(boundsInfo ? boundsInfo.west : -3.14, boundsInfo ? boundsInfo.south : -1.57, boundsInfo ? boundsInfo.east : 3.14, boundsInfo ? boundsInfo.north : 1.57)
        })
        const realProvider = this.viewer.imageryLayers.addImageryProvider(imageryProvider);
        imageryProvider.readyPromise.then(() => {
          this.providerObj[id] = realProvider;
          if (isZoomTo) {
            this.viewer.camera.setView({
              destination: realProvider.imageryProvider.rectangle,
              orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-90),
                roll: 0.0
              }
            });
          }
        }).catch(function (error) {
          console.log(error);
        });
      }

      /* 显示隐藏图层 */
      showHideImagery(layarId, flag) {
        this.providerObj[layarId].show = flag;
        if (flag) {
          this.viewer.camera.setView({
            destination: this.providerObj[layarId].imageryProvider.rectangle,
            orientation: {
              heading: Cesium.Math.toRadians(0.0),
              pitch: Cesium.Math.toRadians(-90),
              roll: 0.0
            }
          });
        }
      }


      /* 定位图层 */
      zoomToImagery(layarId) {
        this.viewer.camera.setView({
          destination: this.providerObj[layarId].imageryProvider.rectangle,
          orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-90),
            roll: 0.0
          }
        });
      }
    }

    ufi3d.DiffCircle = class DiffCircle {
      constructor(viewer) {
        this.diffCircle = [];
        this.viewer = viewer;
      }

      //添加雷达扫描
      addDiffCircle(position, maxRadius, scanColor, duration) {
        let cartographicCenter = Cesium.Cartographic.fromCartesian(position);
        let ScanPostStage = this.createPostStage(this.viewer, cartographicCenter, maxRadius, Cesium.Color.fromCssColorString(scanColor), duration);
        this.viewer.scene.postProcessStages.add(ScanPostStage);
        this.diffCircle.push(ScanPostStage);
      }


      //创建PostStage
      createPostStage(viewer, cartographicCenter, maxRadius, scanColor, duration) {
        var _Cartesian3Center = Cesium.Cartographic.toCartesian(cartographicCenter);
        var _Cartesian4Center = new Cesium.Cartesian4(_Cartesian3Center.x, _Cartesian3Center.y, _Cartesian3Center.z, 1);

        var _CartographicCenter1 = new Cesium.Cartographic(cartographicCenter.longitude, cartographicCenter.latitude, cartographicCenter.height + 500);
        var _Cartesian3Center1 = Cesium.Cartographic.toCartesian(_CartographicCenter1);
        var _Cartesian4Center1 = new Cesium.Cartesian4(_Cartesian3Center1.x, _Cartesian3Center1.y, _Cartesian3Center1.z, 1);

        var _time = (new Date()).getTime();

        var _scratchCartesian4Center = new Cesium.Cartesian4();
        var _scratchCartesian4Center1 = new Cesium.Cartesian4();
        var _scratchCartesian3Normal = new Cesium.Cartesian3();

        var ScanPostStage = new Cesium.PostProcessStage({
          fragmentShader: this.getDiffCircleShader(),
          uniforms: {
            u_scanCenterEC: function () {
              return Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
            },
            u_scanPlaneNormalEC: function () {
              var temp = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center, _scratchCartesian4Center);
              var temp1 = Cesium.Matrix4.multiplyByVector(viewer.camera._viewMatrix, _Cartesian4Center1, _scratchCartesian4Center1);
              _scratchCartesian3Normal.x = temp1.x - temp.x;
              _scratchCartesian3Normal.y = temp1.y - temp.y;
              _scratchCartesian3Normal.z = temp1.z - temp.z;

              Cesium.Cartesian3.normalize(_scratchCartesian3Normal, _scratchCartesian3Normal);
              return _scratchCartesian3Normal;
            },
            u_radius: function () {
              return maxRadius * (((new Date()).getTime() - _time) % duration) / duration;
            },
            u_scanColor: scanColor
          }
        });

        return ScanPostStage;
      }


      getDiffCircleShader() {
        return "uniform sampler2D colorTexture;\n" +
          "uniform sampler2D depthTexture;\n" +
          "varying vec2 v_textureCoordinates;\n" +
          "uniform vec4 u_scanCenterEC;\n" +
          "uniform vec3 u_scanPlaneNormalEC;\n" +
          "uniform float u_radius;\n" +
          "uniform vec4 u_scanColor;\n" +

          "vec4 toEye(in vec2 uv, in float depth)\n" +
          " {\n" +
          " vec2 xy = vec2((uv.x * 2.0 - 1.0),(uv.y * 2.0 - 1.0));\n" +
          " vec4 posInCamera =czm_inverseProjection * vec4(xy, depth, 1.0);\n" +
          " posInCamera =posInCamera / posInCamera.w;\n" +
          " return posInCamera;\n" +
          " }\n" +

          "vec3 pointProjectOnPlane(in vec3 planeNormal, in vec3 planeOrigin, in vec3 point)\n" +
          "{\n" +
          "vec3 v01 = point -planeOrigin;\n" +
          "float d = dot(planeNormal, v01) ;\n" +
          "return (point - planeNormal * d);\n" +
          "}\n" +

          "float getDepth(in vec4 depth)\n" +
          "{\n" +
          "float z_window = czm_unpackDepth(depth);\n" +
          "z_window = czm_reverseLogDepth(z_window);\n" +
          "float n_range = czm_depthRange.near;\n" +
          "float f_range = czm_depthRange.far;\n" +
          "return (2.0 * z_window - n_range - f_range) / (f_range - n_range);\n" +
          "}\n" +

          "void main()\n" +
          "{\n" +
          "gl_FragColor = texture2D(colorTexture, v_textureCoordinates);\n" +
          "float depth = getDepth( texture2D(depthTexture, v_textureCoordinates));\n" +
          "vec4 viewPos = toEye(v_textureCoordinates, depth);\n" +
          "vec3 prjOnPlane = pointProjectOnPlane(u_scanPlaneNormalEC.xyz, u_scanCenterEC.xyz, viewPos.xyz);\n" +
          "float dis = length(prjOnPlane.xyz - u_scanCenterEC.xyz);\n" +
          "if(dis < u_radius)\n" +
          "{\n" +
          "float f = 1.0 -abs(u_radius - dis) / u_radius;\n" +
          "f = pow(f, 4.0);\n" +
          "gl_FragColor = mix(gl_FragColor, u_scanColor, f);\n" +
          "}\n" +
          "}\n";
      }

      //清除雷达扫描
      clearRadarScans() {
        this.diffCircle.forEach(element => {
          this.viewer.scene.postProcessStages.remove(element);
        });
        this.diffCircle = [];
      }

    }

    /**
     * 水波纹扩散材质
     * 
     * 
     * @param {*} color  颜色
     * @param {*} duration 持续时间 毫秒
     * @param {*} count  波浪数量
     * @param {*} gradient 渐变曲率
     */
    class CircleWaveMaterialProperty {
      constructor(ob) {
        this._definitionChanged = new Cesium.Event()
        this._color = undefined
        this._colorSubscription = undefined
        this.color = ob.color
        this.duration = Cesium.defaultValue(ob.duration, 1000)
        this.count = Cesium.defaultValue(ob.count, 2)
        if (this.count <= 0) {
          this.count = 1
        }
        this.gradient = Cesium.defaultValue(ob.gradient, 0.1)
        if (this.gradient === 0) {
          this.gradient = 0
        }
        if (this.gradient > 1) {
          this.gradient = 1
        }
        this._time = new Date().getTime()
      }
    }

    // 点效果集合 父类
    class Effect {
      viewer;
      id;
      duration;
      maxRadius;
      pointDraged;
      leftDownFlag;
      update_position;
      constructor(viewer, id) {
        this.viewer = viewer
        this.id = id
        this.duration = 1000
        this.maxRadius = 1000
        this.pointDraged = null
        this.leftDownFlag = false
      }
      change_duration(d) {
        this.duration = d
      }
      change_color(val) {
        const curEntity = this.viewer.entities.getById(this.id)
        curEntity._ellipse._material.color = new Cesium.Color.fromCssColorString(
          val
        )
      }
      change_position(p) {
        const cartesian3 = Cesium.Cartesian3.fromDegrees(
          parseFloat(p[0]),
          parseFloat(p[1]),
          parseFloat(p[2])
        )
        const curEntity = this.viewer.entities.getById(this.id)
        curEntity.position = cartesian3
      }
      del() {
        this.viewer.entities.removeById(this.id)
      }
      add(
        position,
        color,
        maxRadius,
        duration,
        isEdit = false
      ) {
        const _this = this
        this.duration = duration
        this.maxRadius = maxRadius
        if (!isEdit) {
          return
        }

        function leftDownAction(e) {
          _this.pointDraged = _this.viewer.scene.pick(e.position) // 选取当前的entity
          if (
            _this.pointDraged &&
            _this.pointDraged.id &&
            _this.pointDraged.id.id === _this.id
          ) {
            _this.leftDownFlag = true
            _this.viewer.scene.screenSpaceCameraController.enableRotate = false // 锁定相机
          }
        }

        function leftUpAction() {
          _this.leftDownFlag = false
          _this.pointDraged = null
          _this.viewer.scene.screenSpaceCameraController.enableRotate = true // 解锁相机
        }

        function mouseMoveAction(e) {
          if (
            _this.leftDownFlag === true &&
            _this.pointDraged !== null &&
            _this.pointDraged !== undefined
          ) {
            const ray = _this.viewer.camera.getPickRay(e.endPosition)
            const cartesian = _this.viewer.scene.globe.pick(ray, _this.viewer.scene)
            _this.pointDraged.id.position = cartesian // 此处根据具体entity来处理，也可能是pointDraged.id.position=cartesian;
            // 这里笛卡尔坐标转 经纬度
            const ellipsoid = _this.viewer.scene.globe.ellipsoid
            const cartographic = ellipsoid.cartesianToCartographic(cartesian)
            const lat = Cesium.Math.toDegrees(cartographic.latitude)
            const lng = Cesium.Math.toDegrees(cartographic.longitude)
            let alt = cartographic.height
            alt = alt < 0 ? 0 : alt
            if (_this.update_position) {
              _this.update_position([lng.toFixed(8), lat.toFixed(8), alt])
            }
          }
        }
        this.viewer.screenSpaceEventHandler.setInputAction(
          leftDownAction,
          Cesium.ScreenSpaceEventType.LEFT_DOWN
        )
        this.viewer.screenSpaceEventHandler.setInputAction(
          leftUpAction,
          Cesium.ScreenSpaceEventType.LEFT_UP
        )
        this.viewer.screenSpaceEventHandler.setInputAction(
          mouseMoveAction,
          Cesium.ScreenSpaceEventType.MOUSE_MOVE
        )
      }
    }

    /* czml动态轨迹 */
    ufi3d.DynamicTrajectory = class DynamicTrajectory {

      constructor(viewer) {
        this.viewer = viewer
        this.positionsArr = [];
        this.czml = [];
      }

      loadTrajectory(points, materialColor) {
        for (let i = 0; i < points.length; i++) {
          this.positionsArr.push(i);
          this.positionsArr.push(points[i].longitude);
          this.positionsArr.push(points[i].latitude);
          this.positionsArr.push(points[i].altitude);
        }

        // 时间间隔是10，数据量是171    过去的时间是171*10 = 1710秒，
        this.czml = [{
            id: "document",
            name: "CZML Path",
            version: "1.0",
            clock: {
              interval: "2012-08-04T00:00:00Z/2012-08-04T23:59:59Z",
              currentTime: "2012-08-04T23:59:59Z",
              multiplier: 1,
            },
          },
          {
            id: "path",
            name: "path with GPS flight data",
            description: "<p>Hang gliding flight log data from Daniel H. Friedman.<br>Icon created by Larisa Skosyrska from the Noun Project</p>",
            //用来标示一个对象的数据在什么时候是可用的，默认在所有时间内可用，假如availability变化了或者被发现是不正确的，那么随后的packet将会更新它的值。
            availability: "2012-08-04T00:00:00Z/2012-08-04T23:59:59Z",
            path: {
              material: {
                polylineGlow: {
                  glowPower: 1, //荧光效果，线周边发亮
                  taperPower: 1, //控制一头线粗 一头线细，为1时线的两头粗细一样
                  color: {
                    rgba: materialColor,
                  }
                }
              },
              width: 5,
              // leadTime: 1000,     //提前显示的时间范围内的路径，默认显示全部路径
              // trailTime: 1000,    //已发生的能够保留的时间范围内的路径
              // resolution: 5,     //对路径进行采样的最大步长
            },
            // billboard: {
            //   image: require('./../assets/uav.png'),
            //   scale: 0.5,
            //   eyeOffset: {
            //     cartesian: [0.0, 0.0, -10.0],
            //   },
            // },
            position: {
              epoch: "2012-08-04T00:00:00Z",
              cartographicDegrees: this.positionsArr,
            },
          },
        ];
        this.viewer.dataSources
          .add(Cesium.CzmlDataSource.load(this.czml))
          .then((ds) => {
            this.viewer.clock.shouldAnimate = true;
          });
      }

      addStep(position) {
        this.positionsArr.push(this.positionsArr.length)
        this.positionsArr.push(position.longitude);
        this.positionsArr.push(position.latitude);
        this.positionsArr.push(position.altitude);
        //路径最后添加节点
        this.czml[1].position.cartographicDegrees.push(this.positionsArr.length, position.longitude, position.latitude, position.altitude);
        //修改当前时间
        // this.czml[0].clock.currentTime = this.czml[0].clock.currentTime;
        //清空之前数据，否则数据越来越多
        this.viewer.dataSources.removeAll();
        //重新添加修改后的数据
        this.viewer.dataSources.add(Cesium.CzmlDataSource.load(this.czml));
      }

    }

    ufi3d.TrackLine = class TrackLine {
      constructor(viewer) {
        this.viewer = viewer;
        this.totalTime = 0;
      }

      addTrackLine(positions, modalUrl, lineType, lineColor, lineWidth, interval) {
        if (positions.length === 1) {
          // 解决一个点时无法定位到轨迹的问题，多加一个点，时间设置为原来的一半
          positions[1] = positions[0];
          interval = interval / 2;
        }
        const totalSeconds = this.getSiteTimes(positions.length, interval);
        this.totalTime += totalSeconds;
        let startTime = Cesium.JulianDate.fromDate(new Date());
        console.log(new Date());
        console.log(startTime);
        let stopTime = Cesium.JulianDate.addSeconds(startTime, totalSeconds, new Cesium.JulianDate());
        this.viewer.clock.startTime = startTime.clone();
        this.viewer.clock.stopTime = stopTime.clone();
        if (positions.length === 1) {
          this.viewer.clock.currentTime = startTime.clone();
        } else {
          this.viewer.clock.currentTime = stopTime.clone();
        }
        // this.viewer.timeline.zoomTo(startTime, stopTime);
        this.startTime = startTime.clone();
        this.stopTime = stopTime.clone();
        this.viewer.clock.shouldAnimate = true;
        this.viewer.clock.clockRange = Cesium.ClockRange.CLAMPED;
        const linePositions = this.computeCirclularFlight(positions, startTime, interval);
        // 设置平滑轨迹（利用设置插入位置时要使用的算法和度数。）
        // this.property.setInterpolationOptions({
        //   interpolationDegree: 5, //值越大，越平滑，拐角越趋向圆形
        //   interpolationAlgorithm: Cesium.LagrangePolynomialApproximation,
        // });
        const material = this.switchMaterial(lineType, lineColor);
        this.airplaneEntity = this.viewer.entities.add({
          // availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
          //   start: startTime,
          //   stop: stopTime
          // })]),
          position: this.property,
          // orientation: new Cesium.VelocityOrientationProperty(this.property),
          // orientation: new Cesium.Cartesian3(10, 10, 0),
          orientation: Cesium.Transforms.headingPitchRollQuaternion(
            Cesium.Cartesian3.fromDegrees(positions[0].longitude, positions[0].latitude),
            new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(0), // 设置这个属性即可（顺时针旋转的角度值）
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(0)
            )
          ),
          //控制镜头在模型的前后，左右，上下
          viewFrom: new Cesium.Cartesian3(0, 10, 20),
          model: {
            uri: modalUrl ? modalUrl : '',
            clampAnimations: true,
            scale: 0.1,
            minimumPixelSize: 50,
            maximumScale: 60,
            // color: Cesium.Color.fromCssColorString("#FF0000")
          },
          path: {
            show: true,
            leadTime: 0, //指定要显示的对象前面的秒数
            trailTime: 1000000000, //该属性指定要显示的对象后面的秒数。越小轨迹越短
            width: lineWidth,
            resolution: 1,
            material: material
          }
        });
        this.viewer.clock.shouldAnimate = false
        if (positions.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      /* 返回航线实体 */
      getLineEntity() {
        return this.airplaneEntity
      }
      addPoint(position, interval) {
        const stopTime = Cesium.JulianDate.addSeconds(this.stopTime, interval, new Cesium.JulianDate());
        this.totalTime += interval;
        this.viewer.clock.stopTime = stopTime.clone();
        this.stopTime = stopTime.clone();
        // this.viewer.timeline.zoomTo(this.startTime, this.stopTime);
        const center = Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, position.altitude);
        // 0-1702  0-12   12时刻才开始，应该是10时刻开始 12时刻结束
        const time = Cesium.JulianDate.addSeconds(this.startTime, this.totalTime, new Cesium.JulianDate());
        this.property.addSample(stopTime, center);
        this.airplaneEntity.position = this.property;
        this.viewer.clock.shouldAnimate = true
      }

      clearTrackLine() {
        // 清空轨迹
        this.viewer.entities.remove(this.airplaneEntity);
        this.totalTime = 0;
      }

      getSiteTimes(arrLength, timeStepInSeconds) {
        if (arrLength === 1) {
          return timeStepInSeconds
        } else {
          return timeStepInSeconds * (arrLength - 1); //花费时间
        }
      }

      //获取飞行的动画点位
      computeCirclularFlight(pArr, startTime, timeStepInSeconds) {
        this.property = new Cesium.SampledPositionProperty();
        const positions = [];
        for (let i = 0; i < pArr.length; i++) {
          const position = Cesium.Cartesian3.fromDegrees(pArr[i].longitude, pArr[i].latitude, pArr[i].altitude);
          const time = Cesium.JulianDate.addSeconds(startTime, i * timeStepInSeconds, new Cesium.JulianDate());
          // 0时刻开始，1700时刻结束
          // 0时刻开始，10
          positions.push(position)
          this.property.addSample(time, position);
          // this.viewer.entities.add({
          //   description: `Location: (${pArr[i].longitude}, ${pArr[i].latitude}, ${pArr[i].altitude})`,
          //   position: position,
          //   point: {
          //     pixelSize: pointSize,
          //     color: Cesium.Color.fromCssColorString(color)
          //   }
          // });
        }
        return positions;
      }

      switchMaterial(type, color) {
        const materialColor = Cesium.Color.fromCssColorString(color);
        let material = undefined;
        switch (type) {
          // 发光材质
          case 'glow':
            material = new Cesium.PolylineGlowMaterialProperty({
              glowPower: 1, //荧光效果，线周边发亮
              taperPower: 0.2, //控制一头线粗 一头线细，为1时线的两头粗细一样
              color: materialColor,
            })
            break;
            // 边框线
          case 'outline':
            material = new Cesium.PolylineOutlineMaterialProperty({
              color: Cesium.Color.WHITE,
              outlineColor: materialColor,
              outlineWidth: 1
            })
            break;
            // 斑马纹
          case 'dash':
            material = new Cesium.PolylineDashMaterialProperty({
              color: materialColor,
              gapColor: Cesium.Color.WHITE,
              dashLength: 50
            })
            break;
            // 尾部箭头
          case 'arrow':
            material = new Cesium.PolylineArrowMaterialProperty(
              materialColor
            )
            break;
          case '':
            material = materialColor
            break;
          default:
            material = materialColor
            break;
        }
        return material;
      }

      /* 第三视角锁定 */
      exchangeAngle(flag) {
        if (flag) {
          this.viewer.camera.cancelFlight();
          this.viewer.trackedEntity = this.airplaneEntity;
        } else {
          this.viewer.trackedEntity = undefined;
        }
      }
    }

    // 水波纹
    ufi3d.CircleWave = class CircleWave extends Effect {
      count;
      constructor(viewer) {
        super(viewer)
      }
      change_duration(d) {
        super.change_duration(d)
        const curEntity = this.viewer.entities.getById(this.id)
        curEntity._ellipse._material.duration = d
      }
      change_waveCount(d) {
        const curEntity = this.viewer.entities.getById(this.id)
        curEntity._ellipse._material.count = d
      }
      add(id, position, color, maxRadius, duration, isedit = false, count = 3) {
        if (!Cesium.Material.CircleWaveMaterialType) {
          Object.defineProperties(CircleWaveMaterialProperty.prototype, {
            isConstant: {
              get: function () {
                return false
              },
            },
            definitionChanged: {
              get: function () {
                return this._definitionChanged
              },
            },
            color: Cesium.createPropertyDescriptor('color'),
            duration: Cesium.createPropertyDescriptor('duration'),
            count: Cesium.createPropertyDescriptor('count'),
          })
          CircleWaveMaterialProperty.prototype.getType = function () {
            return Cesium.Material.CircleWaveMaterialType
          }
          CircleWaveMaterialProperty.prototype.getValue = function (
            time,
            result
          ) {
            if (!Cesium.defined(result)) {
              result = {}
            }
            result.color = Cesium.Property.getValueOrClonedDefault(
              this._color,
              time,
              Cesium.Color.WHITE,
              result.color
            )
            result.time =
              ((new Date().getTime() - this._time) % this.duration) / this.duration
            result.count = this.count
            result.gradient = 1 + 10 * (1 - this.gradient)
            return result
          }
          CircleWaveMaterialProperty.prototype.equals = function (other) {
            const reData = (
              this === other ||
              (other instanceof CircleWaveMaterialProperty &&
                Cesium.Property.equals(this._color, other._color))
            )
            return reData
          }
          Cesium.CircleWaveMaterialProperty = CircleWaveMaterialProperty
          Cesium.Material.CircleWaveMaterialType = 'CircleWaveMaterial'
          Cesium.Material.CircleWaveSource = `
                                              czm_material czm_getMaterial(czm_materialInput materialInput) {
                                                czm_material material = czm_getDefaultMaterial(materialInput);
                                                material.diffuse = 1.5 * color.rgb;
                                                vec2 st = materialInput.st;
                                                vec3 str = materialInput.str;
                                                float dis = distance(st, vec2(0.5, 0.5));
                                                float per = fract(time);
                                                if (abs(str.z) > 0.001) {
                                                  discard;
                                                }
                                                if (dis > 0.5) {
                                                  discard;
                                                } else {
                                                  float perDis = 0.5 / count;
                                                  float disNum;
                                                  float bl = .0;
                                                  for (int i = 0; i <= 9; i++) {
                                                    if (float(i) <= count) {
                                                      disNum = perDis *float(i) - dis + per / count;
                                                      if (disNum > 0.0) {
                                                        if (disNum < perDis) {
                                                          bl = 1.0 - disNum / perDis;
                                                        } else if(disNum - perDis < perDis) {
                                                          bl = 1.0 - abs(1.0 - disNum / perDis);
                                                        }
                                                        material.alpha = pow(bl, gradient);
                                                      }
                                                    }
                                                  }
                                                }
                                                return material;
                                              }
                                              `
          Cesium.Material._materialCache.addMaterial(
            Cesium.Material.CircleWaveMaterialType, {
              fabric: {
                type: Cesium.Material.CircleWaveMaterialType,
                uniforms: {
                  color: new Cesium.Color(1, 0, 0, 1),
                  time: 1,
                  count: 1,
                  gradient: 0.1,
                },
                source: Cesium.Material.CircleWaveSource,
              },
              translucent: function () {
                return true
              },
            }
          )
        }

        super.add(position, color, maxRadius, duration, isedit)
        const _this = this
        this.count = count

        this.viewer.entities.add({
          id: id,
          position: Cesium.Cartesian3.fromDegrees(
            position[0],
            position[1],
            position[2]
          ),
          ellipse: {
            // height: position[2],
            semiMinorAxis: new Cesium.CallbackProperty(function () {
              return _this.maxRadius
            }, false),
            semiMajorAxis: new Cesium.CallbackProperty(function () {
              return _this.maxRadius
            }, false),
            material: new Cesium.CircleWaveMaterialProperty({
              duration: duration,
              gradient: 0,
              color: new Cesium.Color.fromCssColorString(color),
              count: count,
            }),
          },
        })
      }
      updateCircleParam(entityId, color, duration = 10000) {
        const circleEntity = this.viewer.entities.getById(entityId);
        if (circleEntity) {
          circleEntity.ellipse.material = new Cesium.CircleWaveMaterialProperty({
            duration: duration,
            gradient: 0,
            color: new Cesium.Color.fromCssColorString(color),
            count: 3,
          })
        }
      }
    }

    function computeDistance(positions) {
      let distance = 0;
      for (let i = 0; i < positions.length - 1; i++) {
        distance += Cesium.Cartesian3.distance(positions[i], positions[i + 1]);
      }
      return distance;
    }


    ufi3d.MeasureDistance = class MeasureDistance {
      constructor(viewer, rightCallback) {
        this.viewer = viewer;
        this.initEvents();
        this.positions = [];
        this.tempPositions = [];
        this.measureEntities = [];
        this.lineEntity = null;
        this.moveLabelEntity = null;
        this.rightCallback = rightCallback;
      }

      //初始化事件
      initEvents() {
        // 输入事件捕捉器
        this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
      }

      activate() {
        this.deactivate();
        this.viewer._element.style.cursor = 'crosshair';
        this.isMeasure = true;
        this.registerEvents(); //注册鼠标事件 
        this.createMoveLabel(); //移动文本
      }

      //禁用
      deactivate() {
        if (!this.isMeasure) return;
        this.unRegisterEvents();
        this.viewer._element.style.cursor = 'default';
        this.isMeasure = false;
        this.tempPositions = [];
        this.positions = [];
      }

      //注册鼠标事件
      registerEvents() {
        this.leftClickEvent();
        this.rightClickEvent();
        this.mouseMoveEvent();
      }

      //移除鼠标事件（左击、右击、鼠标移动）
      unRegisterEvents() {
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }

      //左键点击事件
      leftClickEvent() {
        //单击鼠标左键画点点击事件
        this.handler.setInputAction(e => {
          // 地形位置
          let position = this.viewer.scene.pickPosition(e.position);
          if (!position) {
            // 椭球位置
            const ellipsoid = this.viewer.scene.globe.ellipsoid;
            position = this.viewer.scene.camera.pickEllipsoid(e.position, ellipsoid);
          }
          if (!position) return;
          this.positions.push(position);
          if (this.positions.length === 1) { //首次点击  
            // 添加线
            this.createLineEntity();
            if (this.moveLabelEntity) {
              this.moveLabelEntity.show = true;
            }
          }
          // 添加点
          this.createVertex();
          // 添加文字
          this.createText();
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      }

      //鼠标移动事件
      mouseMoveEvent() {
        this.handler.setInputAction(e => {
          if (!this.isMeasure) return;
          let position = this.viewer.scene.pickPosition(e.endPosition);
          if (!position) {
            position = this.viewer.scene.camera.pickEllipsoid(e.startPosition, this.viewer.scene.globe.ellipsoid);
          }
          if (!position) return;
          this.handleMoveEvent(position);
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }

      //处理鼠标移动
      handleMoveEvent(position) {
        if (this.positions.length < 1) return;
        this.tempPositions = this.positions.concat(position);
        this.moveLabelEntity.position = position;
        this.moveLabelEntity.label.text = computeDistance(this.tempPositions).toFixed(2) + t("42");
      }

      //右键事件
      rightClickEvent() {
        this.handler.setInputAction(() => {
          if (this.isMeasure && this.positions.length > 1) {
            this.lineEntity.polyline = {
              positions: this.positions,
              width: 2,
              material: Cesium.Color.YELLOW,
              depthFailMaterial: Cesium.Color.YELLOW
            };

            //返回的测距点数组数据
            let rangingData = [];

            //处理测距-数组点坐标转经纬度
            this.positions.forEach(point => {
              let cartesian = new Cesium.Cartesian3(point.x, point.y, point.z);
              let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
              let longitude = Cesium.Math.toDegrees(cartographic.longitude);
              let latitude = Cesium.Math.toDegrees(cartographic.latitude);
              let altitude = cartographic.height;
              rangingData.push({
                longitude,
                latitude,
                altitude
              })
            })

            this.positions = [];
            this.tempPositions = [];
            this.isMeasure = true;
            this.moveLabelEntity.show = false;

            //回调右击事件 -- 类型（测距）、线段id、线段点数组
            this.rightCallback('LINE', this.lineEntity.id, rangingData)
            rangingData = [];
          }
        }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      }

      //创建线对象
      createLineEntity() {
        this.lineEntity = this.viewer.entities.add({
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return this.tempPositions;
            }, false),
            width: 2,
            material: Cesium.Color.YELLOW,
            depthFailMaterial: Cesium.Color.YELLOW
          }
        })
        this.measureEntities.push(this.lineEntity);
      }

      //创建线节点
      createVertex() {
        const vertexEntity = this.viewer.entities.add({
          position: this.positions[this.positions.length - 1],
          // id: "MeasureDistanceVertex" + this.positions[this.positions.length - 1],
          type: "MeasureDistanceVertex",
          billboard: {
            image: require('./../asset/img/centerPlan/circle.png'),
            scale: 1,
            color: Cesium.Color.FUCHSIA,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
        });
        this.measureEntities.push(vertexEntity);
      }


      createText() {
        const pLength = this.positions.length;
        if (pLength >= 2) {
          const midPoint = Cesium.Cartesian3.midpoint(this.positions[pLength - 1], this.positions[pLength - 2], new Cesium.Cartesian3())
          const vertexEntity = this.viewer.entities.add({
            position: midPoint,
            // id: "MeasureDistanceLine" + this.positions[pLength - 1],
            type: "MeasureDistanceLine",
            label: {
              font: '12px sans-serif',
              text: computeDistance(this.positions).toFixed(2) + t("42"),
              showBackground: true,
              backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
              backgroundPadding: new Cesium.Cartesian2(4, 4),
              pixelOffset: new Cesium.Cartesian2(0, -20),
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 10000),
            },
          });
          this.measureEntities.push(vertexEntity);
        }
      }


      createMoveLabel() {
        this.moveLabelEntity = this.viewer.entities.add({
          label: {
            font: '12px sans-serif',
            text: computeDistance(this.tempPositions).toFixed(2) + t("42"),
            showBackground: true,
            backgroundColor: new Cesium.Color(0, 0, 0, 0.6),
            backgroundPadding: new Cesium.Cartesian2(4, 4),
            pixelOffset: new Cesium.Cartesian2(0, -20),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 10000),
          },
        })
      }

      clear() {
        //清除线对象
        this.viewer.entities.remove(this.lineEntity);
        this.lineEntity = null;
        //清除移动文本对象
        this.viewer.entities.remove(this.moveLabelEntity);
        this.moveLabelEntity = null;

        //清除节点
        this.measureEntities.forEach(item => {
          this.viewer.entities.remove(item);
        });
        this.measureEntities = [];
      }
    }

    function computeArea(viewer, positions) {
      var ellipsoid = viewer.scene.globe.ellipsoid;
      var lastArr = []
      lastArr.push(positions[0]) //人为设置结尾点为起点
      var newPositions = positions.concat(lastArr)
      const positionsArr = [];
      for (let i = 0; i < newPositions.length; i++) {
        let positionArr = []
        var cartographic = ellipsoid.cartesianToCartographic(newPositions[i]);
        // 转成经纬度存储
        positionArr.push(Cesium.Math.toDegrees(cartographic.longitude));
        positionArr.push(Cesium.Math.toDegrees(cartographic.latitude));
        positionsArr.push(positionArr)
      }
      // turf计算面积
      var polygon = turf.polygon([
        positionsArr
      ]);

      var area = turf.area(polygon);
      return area.toFixed(2);
    }


    //面积测量类
    ufi3d.MeasureArea = class MeasureArea {
      constructor(viewer, rightCallback) {
        this.viewer = viewer;
        this.initEvents();
        this.positions = [];
        this.tempPositions = [];
        this.vertexEntities = [];
        this.rightCallback = rightCallback;
      }

      //初始化事件
      initEvents() {
        this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
      }

      //激活
      activate() {
        this.deactivate();
        this.registerEvents(); //注册鼠标事件  
        //设置鼠标状态 
        this.viewer._element.style.cursor = 'crosshair';
        this.isMeasure = true;
      }

      //禁用
      deactivate() {
        if (!this.isMeasure) return;
        this.unRegisterEvents();
        this.viewer._element.style.cursor = 'pointer';
        this.isMeasure = false;
        this.tempPositions = [];
        this.positions = [];
      }

      //清空绘制
      clear() {
        //清除线面对象
        this.viewer.entities.remove(this.polygonEntity);
        this.polygonEntity = undefined;

        //清除节点
        this.vertexEntities.forEach(item => {
          this.viewer.entities.remove(item);
        });
        this.vertexEntities = [];

        this.viewer.entities.remove(this.mesureResultEntity);
        this.mesureResultEntity = undefined;

      }

      //创建面对象
      createPolygonEntity() {
        this.polygonEntity = this.viewer.entities.add({
          polygon: {
            hierarchy: new Cesium.CallbackProperty(() => {
              return new Cesium.PolygonHierarchy(this.tempPositions);
              //使用最新1.72的时候 必须返回PolygonHierarchy类型 Cannot read property 'length' of undefined
              //低版本好像都可以
            }, false),
            material: Cesium.Color.RED.withAlpha(0.4),
            // perPositionHeight: true, //  
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          },
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return this.tempPositions.concat(this.tempPositions[0]);
            }, false),
            width: 1,
            material: new Cesium.PolylineDashMaterialProperty({
              color: Cesium.Color.YELLOW,
            }),
            depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
              color: Cesium.Color.YELLOW,
            }),
          }

        })
      }

      //创建节点
      createVertex() {
        let vertexEntity = this.viewer.entities.add({
          position: this.positions[this.positions.length - 1],
          type: "MeasureAreaVertex",
          point: {
            color: Cesium.Color.FUCHSIA,
            pixelSize: 12,
            // disableDepthTestDistance: 500,
          },
        });
        this.vertexEntities.push(vertexEntity);
      }

      //测量结果标签
      createResultLabel() {
        console.log(t('43'))
        this.mesureResultEntity = this.viewer.entities.add({
          position: new Cesium.CallbackProperty(() => {
            return this.getCenterPosition()
          }, false),
          type: "MeasureAreaResult",
          label: {
            text: new Cesium.CallbackProperty(() => {
              return t("44") + computeArea(this.viewer, this.tempPositions) + t("45");
            }, false),
            scale: 1,
            font: 'normal 28px MicroSoft YaHei',
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 10000),
            scaleByDistance: new Cesium.NearFarScalar(1000, 1, 3000, 0.4),
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -30),
            outlineWidth: 9,
            outlineColor: Cesium.Color.YELLOW,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          },
        });

      }

      cartesian3ToPoint3D(position) {
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        const lon = Cesium.Math.toDegrees(cartographic.longitude);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        return {
          x: lon,
          y: lat,
          z: cartographic.height
        };
      }

      //获取节点的中心点
      getCenterPosition() {
        let points = [];
        if (this.tempPositions.length < 3) return this.tempPositions[0];
        this.tempPositions.forEach(position => {
          const point3d = this.cartesian3ToPoint3D(position);
          points.push([point3d.x, point3d.y]);
        })

        //构建turf.js  lineString
        let geo = turf.lineString(points);
        let bbox = turf.bbox(geo);
        let bboxPolygon = turf.bboxPolygon(bbox);
        let pointOnFeature = turf.center(bboxPolygon);
        let lonLat = pointOnFeature.geometry.coordinates;
        return Cesium.Cartesian3.fromDegrees(lonLat[0], lonLat[1], 10);
      }


      //注册鼠标事件
      registerEvents() {
        this.leftClickEvent();
        this.rightClickEvent();
        this.mouseMoveEvent();
      }

      //左键点击事件
      leftClickEvent() {
        //单击鼠标左键画点点击事件
        this.handler.setInputAction(e => {
          let position = this.viewer.scene.pickPosition(e.position);
          if (!position) {
            const ellipsoid = this.viewer.scene.globe.ellipsoid;
            position = this.viewer.scene.camera.pickEllipsoid(e.position, ellipsoid);
          }
          if (!position) return;
          this.positions.push(position);
          if (this.positions.length == 1) { //首次点击  
            this.createPolygonEntity();
          }
          this.createVertex();

        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      }

      //鼠标移动事件
      mouseMoveEvent() {
        this.handler.setInputAction(e => {
          if (!this.isMeasure) return;
          let position = this.viewer.scene.pickPosition(e.endPosition);
          if (!position) {
            position = this.viewer.scene.camera.pickEllipsoid(e.startPosition, this.viewer.scene.globe.ellipsoid);
          }
          if (!position) return;
          this.handleMoveEvent(position);
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }

      //处理鼠标移动
      handleMoveEvent(position) {
        if (this.positions.length < 1) return;

        this.tempPositions = this.positions.concat(position);
        if (this.tempPositions.length >= 3 && !this.mesureResultEntity) {
          this.createResultLabel();
        }
      }

      //右键事件
      rightClickEvent() {
        this.handler.setInputAction(() => {
          if (this.isMeasure && this.positions.length > 3) {
            this.tempPositions = [...this.positions];
            this.polygonEntity.polyline = {
              positions: this.positions.concat(this.positions[0]),
              width: 2,
              material: Cesium.Color.YELLOW,
              depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.YELLOW,
              }),
            };

            this.polygonEntity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.tempPositions);
            this.mesureResultEntity.position = this.getCenterPosition();
            this.mesureResultEntity.label.text = t("46") + computeArea(this.viewer, this.positions) + t("45")

            //返回的测面-点数组数据
            let rangingData = [];

            //处理测面-数组点坐标转经纬度
            this.positions.forEach(point => {
              let cartesian = new Cesium.Cartesian3(point.x, point.y, point.z);
              let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
              let longitude = Cesium.Math.toDegrees(cartographic.longitude);
              let latitude = Cesium.Math.toDegrees(cartographic.latitude);
              let altitude = cartographic.height;
              rangingData.push({
                longitude,
                latitude,
                altitude
              })
            })


            this.isMeasure = true;
            this.positions = [];
            this.tempPositions = [];
            this.mesureResultEntity = null;

            //回调右击事件 -- 类型（测距）、线段id、线段点数组
            this.rightCallback('NOODLES', this.polygonEntity.id, rangingData)
            rangingData = [];
          }
        }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      }


      //解除鼠标事件
      unRegisterEvents() {
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        this.handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }
    }
    class Entity {

      constructor(viewer) {
        if (!this.viewer) {
          this.viewer = viewer;
        }
      }
    }

    ufi3d.PointObject = {
      BillboardPrimitive: class BillboardPrimitive extends Primitive {
        constructor(viewer, points, picUrl, scale) {
          super(viewer, new Cesium.BillboardCollection());
          this.labelCollection = this.viewer.scene.primitives.add(new Cesium.LabelCollection())
          this.addPrimitive(points, picUrl, scale)
        }

        addPrimitive(points, picUrl, scale) {

          for (let i = 0; i < points.length; i++) {
            if (points[i].text) {
              this.labelCollection.add({
                id: points[i].id ? points[i].id : undefined, //id
                position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2] ? points[i].position[2] : 0), //位置
                text: points[i].text, //文本
                scale: 0.45, //缩放（1为正常）
                pixelOffset: new Cesium.Cartesian2(-25, 25), //偏移量
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 100000)
              })
            }
            const p = this.primitiveCollection.add({
              position: Cesium.Cartesian3.fromDegrees(points[i].position[0], points[i].position[1], points[i].position[2] ? points[i].position[2] : 0), //位置
              image: picUrl ? picUrl : undefined, //图像
              rotation: points[i].rotation ? points[i].rotation : 0.0, //旋转
              scale: scale ? scale : 1.0, //缩放（1为正常）
              width: points[i].width ? points[i].width : undefined, //图像宽度
              height: points[i].height ? points[i].height : undefined, //图像高度
            });
            this.primitiveArr.push(p)
          }
          return this.primitiveArr
        }

        removeAllPrimitive() {
          this.labelCollection.removeAll();
          super.removeAllPrimitive();
        }

      },
      BillboardEntity: class BillboardEntity extends Entity {
        constructor(viewer, position, picUrl, picScale, billText, modelId) {
          super(viewer)
          return this.addBillboard(position, picUrl, picScale, billText, modelId)
        }

        addBillboard(position, picUrl, picScale, billText, modelId) {
          const entity = this.viewer.entities.add({
            id: modelId,
            name: "video",
            position: Cesium.Cartesian3.fromDegrees(position[0], position[1], position[2] ? position[2] : 0),
            label: { //文字标签
              text: billText,
              fillColor: Cesium.Color.WHITE,
              font: '15px sans-serif',
              pixelOffset: new Cesium.Cartesian2(0, 25), //偏移量
            },
            billboard: { //图标
              image: picUrl ? picUrl : undefined,
              scale: picScale ? picScale : 1.0,
              scaleByDistance: new Cesium.NearFarScalar(0, 1, 8000, 0),
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM
            }
          });
          return entity;
        }
      },
      PointPrimitive: class PointPrimitive extends Primitive {
        constructor(viewer, points, color, size, outlineWidth, outlineColor) {
          super(viewer, new Cesium.PointPrimitiveCollection());
          this.labelCollection = this.viewer.scene.primitives.add(new Cesium.LabelCollection())
          this.addPrimitive(points, color, size, outlineWidth, outlineColor)
        }

        addPrimitive(points, color, size, outlineWidth, outlineColor) {
          for (let i = 0; i < points.length; i++) {
            if (points[i].text) {
              this.labelCollection.add({
                id: points[i].id ? points[i].id : undefined, //id
                position: Cesium.Cartesian3.fromDegrees(points[i].longitude, points[i].latitude, points[i].altitude ? points[i].altitude : 0), //位置
                text: points[i].text, //文本
                scale: 0.45, //缩放（1为正常）
                pixelOffset: new Cesium.Cartesian2(-25, 25), //偏移量
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 100000)
              })
            }
            const p = this.primitiveCollection.add({
              position: Cesium.Cartesian3.fromDegrees(points[i].longitude, points[i].latitude, points[i].altitude ? points[i].altitude : 0), //位置
              pixelSize: size ? size : 5.0, //尺寸
              color: color ? Cesium.Color.fromCssColorString(color) : Cesium.Color.WHITE, //填充颜色
              outlineColor: outlineColor ? Cesium.Color.fromCssColorString(outlineColor) : Cesium.Color.TRANSPARENT, //轮廓颜色
              outlineWidth: outlineWidth ? outlineWidth : 0.0, //轮廓宽度
            });
            this.primitiveArr.push(p)
          }
          return this.primitiveArr
        }

        clear() {
          this.primitiveCollection.removeAll();
          this.labelCollection.removeAll();
          this.primitiveArr = [];
        }
      },
      ModelEntity: class ModelEntity extends Entity {
        constructor(viewer, position, modelUrl, picScale, billText, modelId) {
          super(viewer)
          return this.addBillboard(position, modelUrl, picScale, billText, modelId)
        }


        addBillboard(position, modelUrl, picScale, billText, modelId) {
          const myposition = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, position.alt ? position.alt : 0);
          const entity = this.viewer.entities.add({
            id: modelId,
            name: "video",
            position: myposition,
            orientation: Cesium.Transforms.headingPitchRollQuaternion(
              myposition,
              new Cesium.HeadingPitchRoll(
                Cesium.Math.toRadians(position.heading ? position.heading : 0), // 设置这个属性即可（顺时针旋转的角度值）
                Cesium.Math.toRadians(position.pitch ? position.pitch : 0),
                Cesium.Math.toRadians(position.roll ? position.roll : 0)
              )
            ),
            label: { //文字标签
              text: billText,
              fillColor: Cesium.Color.WHITE,
              font: '15px sans-serif',
              pixelOffset: new Cesium.Cartesian2(0, 25), //偏移量
            },
            model: {
              uri: modelUrl,
              clampAnimations: true,
              scale: picScale,
              // color: Cesium.Color.fromCssColorString("#FF0000")
            }
          });
          return entity;
        }
      },
    }

    /* 线 */
    ufi3d.lineObject = {
      /* primitive线 */
      linePrimitive: class Polyline extends Primitive {
        constructor(viewer, points, materialType, color, width, loop) {
          super(viewer, new Cesium.PolylineCollection());
          this.addPolyline(points, materialType, color, width, loop)
        }

        addPolyline(points, materialType, color, width, loop) {
          const material = this.switchMaterial(materialType, color);
          const positionsArr = [];
          for (let i = 0; i < points.length; i++) {
            positionsArr.push(points[i].longitude);
            positionsArr.push(points[i].latitude);
            positionsArr.push(points[i].altitude);
          }
          const p = this.primitiveCollection.add({
            // id: ployLines[i].id ? ployLines[i].id : undefined,
            // show: ployLines[i].show ? ployLines[i].show : true,
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positionsArr),
            width: width ? width : 2.0,
            // 是否首尾相接
            loop: loop ? loop : false,
            // 轨迹线
            material: material
          });

          // const p = this.viewer.entities.add({
          //   polyline: {
          //     positions: Cesium.Cartesian3.fromDegreesArrayHeights(positionsArr),
          //     width: 5,
          //     material: material,
          //   }
          // });
          this.primitiveArr.push(p)
          return this.primitiveArr
        }

        switchMaterial(type, color) {
          const materialColor = Cesium.Color.fromCssColorString(color);
          let material = undefined;
          // let getCustomMaterialLine = (image, color) => {
          //   return this.customMaterialLine({
          //     image: image,
          //     color: color,
          //     duration: 2000
          //   })
          // }
          switch (type) {
            // 发光材质
            case 'glow':
              material = Cesium.Material.fromType(Cesium.Material.PolylineGlowType, {
                glowPower: 1, //荧光效果，线周边发亮
                taperPower: 1, //控制一头线粗 一头线细，为1时线的两头粗细一样
                color: materialColor // 发光颜色
              })
              break;
              // 淡入淡出材质
            case 'fade':
              material = Cesium.Material.fromType(Cesium.Material.FadeType, {
                repeat: false,
                fadeInColor: materialColor,
                fadeOutColor: materialColor.withAlpha(0),
                fadeDirection: {
                  x: true,
                  y: false
                }
              })
              break;
              // 边框线
            case 'outline':
              material = Cesium.Material.fromType(Cesium.Material.PolylineOutline, {
                color: Cesium.Color.WHITE,
                outlineColor: materialColor,
                outlineWidth: 1
              })
              break;
              // 虚线
            case 'dash':
              material = new Cesium.Material({
                fabric: {
                  type: 'PolylineDash',
                  uniforms: {
                    color: materialColor,
                    gapColor: Cesium.Color.TRANSPARENT,
                    dashLength: 60,
                    dashPattern: 255
                  }
                }
              })
              break;
              // 尾部箭头
            case 'arrow':
              material = Cesium.Material.fromType(Cesium.Material.PolylineArrowType);
              break;
              // 流动箭头
              // case 'flowArrow':
              //   // 创建材质线
              //   // material = getCustomMaterialLine(require('./../assets/img/monitor/src_line2.png'), Cesium.Color.fromCssColorString("#FF0000"));
              //   console.info(material)
              //   break;
            default:
              material = new Cesium.Material({
                fabric: {
                  type: 'Color',
                  uniforms: {
                    color: materialColor
                  }
                }
              })
              break;
          }
          return material;
        }

        customMaterialLine(options) {

          const Color = Cesium.Color,
            defaultValue = Cesium.defaultValue,
            defined = Cesium.defined,
            defineProperties = Object.defineProperties,
            Event = Cesium.Event,
            createPropertyDescriptor = Cesium.createPropertyDescriptor,
            Property = Cesium.Property,
            Material = Cesium.Material,
            defaultColor = Color.WHITE,
            MaterialType = options.MaterialType || 'wallType' + parseInt(Math.random() * 1000);
          // 创建自定义动态材质对象
          function PolylineCustomMaterialProperty(options) {

            options = defaultValue(options, defaultValue.EMPTY_OBJECT);
            // 定义内置属性
            this._definitionChanged = new Event();
            this._color = undefined;
            this._colorSubscription = undefined;
            this.color = options.color || Cesium.Color.BLUE;
            this.duration = options.duration || 1000;
            this._time = undefined;
          }
          // 定义材质对象默认属性
          defineProperties(PolylineCustomMaterialProperty.prototype, {
            isvarant: {
              get: function () {
                return false;
              }
            },
            definitionChanged: {
              get: function () {
                return this._definitionChanged;
              }
            },
            color: createPropertyDescriptor('color')
          });
          // 材质对象需要有type函数 value函数 equals函数
          PolylineCustomMaterialProperty.prototype.getType = function () {
            return MaterialType;
          };
          PolylineCustomMaterialProperty.prototype.getValue = function (time, result) {
            if (!defined(result)) {
              result = {};
            }
            result.color = Property.getValueOrClonedDefault(this._color, time, defaultColor, result.color);
            result.image = options.image;
            if (this._time === undefined) {
              this._time = time.secondsOfDay;
            }
            result.time = (time.secondsOfDay - this._time) * 1000 / this.duration;
            return result;
          };
          PolylineCustomMaterialProperty.prototype.equals = function (other) {
            return this === other || //
              (other instanceof PolylineCustomMaterialProperty &&
                Property.equals(this._color, other._color));
          };
          // 将定义的材质对象添加到cesium的材质队列中
          Material._materialCache.addMaterial(MaterialType, {
            fabric: {
              type: MaterialType,
              uniforms: {
                color: options.color || new Cesium.Color(1.0, 0.0, 0.0, 0.5),
                image: options.image,
                time: options.color.time || 0
              },
              // 动态材质shader
              source: "czm_material czm_getMaterial(czm_materialInput materialInput)\n\
                {\n\
                    czm_material material = czm_getDefaultMaterial(materialInput);\n\
                    vec2 st = materialInput.st;\n\
                    \n\
                    if(texture2D(image, vec2(0.0, 0.0)).a == 1.0){\n\
                        discard;\n\
                    }else{\n\
                        material.alpha = texture2D(image, vec2(1.0 - fract(time - st.s), st.t)).a * color.a;\n\
                    }\n\
                    \n\
                    material.diffuse = max(color.rgb * material.alpha * 3.0, color.rgb);\n\
                    \n\
                    return material;\n\
                }\n\
                ",
            },
            // 透明
            translucent: function () {
              return true;
            }
          })
          return new PolylineCustomMaterialProperty(options);
        }

        clear() {
          this.primitiveCollection.removeAll();
          this.primitiveArr = [];
        }
      }
    }

    /* 航线 */
    ufi3d.Route = class Route {
      constructor(viewer) {
        this.viewer = viewer
      }

      addRoute(points, pointColor, lineType, lineColor, lineWidth, pointSize) {
        const linePositions = this.addPoint(points, pointColor, pointSize)
        this.addLine(linePositions, lineType, lineColor, lineWidth)
      }

      addPoint(points, pointColor, pointSize) {
        const linePositions = [];
        // 添加点
        for (let i = 0; i < points.length; i++) {
          linePositions.push(points[i].longitude)
          linePositions.push(points[i].latitude)
          linePositions.push(points[i].altitude)
          const entity = {
            id: points[i].id ? points[i].id : undefined,
            name: points[i].id,
            description: `<table border="1" cellpadding="10">
          <tr>
            <th>经度</th>
            <th>纬度</th>
            <th>高度</th>
          </tr>
          <tr>
            <td>${points[i].longitude}</td>
            <td>${points[i].latitude}</td>
            <td>${points[i].altitude}</td>
          </tr>
        </table>`,
            position: Cesium.Cartesian3.fromDegrees(points[i].longitude, points[i].latitude, points[i].altitude),
            label: { //文字标签
              text: points[i].text ? points[i].text : '',
              font: '500 14px Helvetica', // 15pt monospace
              fillColor: Cesium.Color.WHITE,
              pixelOffset: new Cesium.Cartesian2(0, 25), //偏移量
            },
            point: {
              pixelSize: pointSize ? pointSize : 10,
              color: Cesium.Color.fromCssColorString(pointColor)
            }
          };
          this.viewer.entities.add(entity);
        }
        return linePositions;
      }

      addLine(linePositions, lineType, lineColor, lineWidth) {
        const material = this.switchMaterial(lineType, lineColor);
        // 添加线
        const lineEntity = {
          id: '',
          description: '',
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(linePositions),
            width: lineWidth ? lineWidth : 5,
            material: material
          }
        };
        this.viewer.entities.add(lineEntity);
      }


      switchMaterial(type, color) {
        const materialColor = Cesium.Color.fromCssColorString(color);
        let material = undefined;
        switch (type) {
          // 发光材质
          case 'glow':
            material = new Cesium.PolylineGlowMaterialProperty({
              glowPower: 1, //荧光效果，线周边发亮
              taperPower: 1, //控制一头线粗 一头线细，为1时线的两头粗细一样
              color: materialColor,
            })
            break;
            // 边框线
          case 'outline':
            material = new Cesium.PolylineOutlineMaterialProperty({
              color: Cesium.Color.WHITE,
              outlineColor: materialColor,
              outlineWidth: 1
            })
            break;
            // 斑马纹
          case 'dash':
            material = new Cesium.PolylineDashMaterialProperty({
              color: materialColor,
              gapColor: Cesium.Color.WHITE,
              dashLength: 50
            })
            break;
            // 尾部箭头
          case 'arrow':
            material = new Cesium.PolylineArrowMaterialProperty(
              materialColor
            )
            break;
          default:
            material = materialColor
            break;
        }
        return material;
      }
    }

    ufi3d.CreateFrustum = class CreateFrustum {
      constructor(viewer, options) {
        this.viewer = viewer;
        this.position = options.position;
        this.orientation = options.orientation;
        this.fov = options.fov || 20;
        this.near = options.near || 0.001;
        this.far = options.far || 1000;
        this.aspectRatio = options.angle1 / options.angle2;
        this.faceColor = options.faceColor;
        this.outlineColor = options.outlineColor;
        this.add();
      }

      // 更新视锥体的位置、姿态、角度、距離
      updateStyle(option) {
        if (option.position) {
          this.position = option.position;
        }
        if (option.orientation) {
          this.orientation = option.orientation;
        }
        if (option.angle1 && option.angle2) {
          // const maxAngle = Math.max(option.angle1, option.angle2);
          // this.fov = maxAngle;
          this.aspectRatio = option.angle1 / option.angle2;
        }
        if (option.fov) {
          this.fov = option.fov;
        }
        if (option.distance) {
          this.far = option.distance;
        }
        this.add();
      }

      // 创建视锥体和轮廓线
      add() {
        this.clear();
        this.addFrustum();
        this.addOutline();
      }

      // 清除视锥体和轮廓线
      clear() {
        this.clearFrustum();
        this.clearOutline();
      }

      // 清除视锥体
      clearFrustum() {
        if (this.frustumPrimitive) {
          this.viewer.scene.primitives.remove(this.frustumPrimitive);
          this.frustumPrimitive = null;
        }
      }

      // 清除轮廓线
      clearOutline() {
        if (this.outlinePrimitive) {
          this.viewer.scene.primitives.remove(this.outlinePrimitive);
          this.outlinePrimitive = null;
        }
      }

      // 创建视锥体
      addFrustum() {
        const frustum = new Cesium.PerspectiveFrustum({
          // 查看的视场角，绕Z轴旋转，以弧度方式输入
          // fov: Cesium.Math.PI_OVER_THREE,
          fov: Cesium.Math.toRadians(this.fov),
          // 视锥体的宽度/高度
          aspectRatio: this.aspectRatio,
          // 近面距视点的距离
          near: this.near,
          // 远面距视点的距离
          far: this.far,
        });
        const geometry = new Cesium.FrustumGeometry({ //在给定的原点和方向上描述一个视锥。
          frustum: frustum, //截锥体
          origin: this.position, //源点
          orientation: this.orientation, //方向四元数
          vertexFormat: Cesium.VertexFormat.POSITION_ONLY, //要计算的顶点属性
        });
        let primitive = new Cesium.Primitive({
          id: 'primitive',
          geometryInstances: new Cesium.GeometryInstance({ //GeometryInstance 允许一个几何体对象位于多个不同位置
            geometry: geometry, //要实例化的几何
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                Cesium.Color.fromCssColorString(this.faceColor)
              ), //颜色
            }, //每个实例的属性
          }), //要渲染的几何实例-或单个几何实例。
          appearance: new Cesium.PerInstanceColorAppearance({
            closed: true,
            flat: true,
          }), //外观着色
          asynchronous: false, //是否将在Web Worker（子线程）上创建几何实例并进行批处理
        });
        // console.log(geometry)

        // const cartesian3 = Cesium.Matrix4.getTranslation(frustum.infiniteProjectionMatrix, new Cesium.Cartesian3());
        // console.log(cartesian3);
        this.frustumPrimitive = this.viewer.scene.primitives.add(primitive);

      }

      // 创建轮廓线
      addOutline() {
        let frustum = new Cesium.PerspectiveFrustum({
          // 查看的视场角度，绕Z轴旋转，以弧度方式输入
          // The angle of the field of view (FOV), in radians. 
          // This angle will be used as the horizontal FOV if the width is greater than the height, otherwise it will be the vertical FOV.
          fov: Cesium.Math.toRadians(this.fov),
          // 视锥体的宽度/高度
          aspectRatio: this.aspectRatio,
          // 近面距视点的距离
          near: this.near,
          // 远面距视点的距离
          far: this.far,
        });
        let geometry = new Cesium.FrustumOutlineGeometry({
          frustum: frustum,
          origin: this.position,
          orientation: this.orientation,
          // vertexFormat: Cesium.VertexFormat.POSITION_ONLY,
        });
        let instance = new Cesium.GeometryInstance({
          geometry: geometry,
          attributes: {
            color: Cesium.ColorGeometryInstanceAttribute.fromColor(
              Cesium.Color.fromCssColorString(this.outlineColor)
            ),
          },
        });
        let primitive = new Cesium.Primitive({
          geometryInstances: instance,
          appearance: new Cesium.PerInstanceColorAppearance({
            closed: true,
            flat: true,
          }),
          asynchronous: false,
        });
        this.outlinePrimitive = this.viewer.scene.primitives.add(primitive);
      }
    }

    ufi3d.PolylineDashArrowMaterialProperty = class PolylineDashArrowMaterialProperty extends Cesium.PolylineDashMaterialProperty {
      constructor(options) {
        const PolylineDashArrowSource = '\
      #ifdef GL_OES_standard_derivatives\n\
      #extension GL_OES_standard_derivatives : enable\n\
      #endif\n\
      \n\
      uniform vec4 color;\n\
      uniform vec4 gapColor;\n\
      uniform float dashLength;\n\
      uniform float dashPattern;\n\
      \n\
      varying float v_polylineAngle;\n\
      varying float v_width;\n\
      \n\
      const float maskLength = 16.0;\n\
      \n\
      mat2 rotate(float rad) {\n\
        float c = cos(rad);\n\
        float s = sin(rad);\n\
        return mat2(\n\
            c, s,\n\
            -s, c\n\
        );\n\
      }\n\
      \n\
      float getPointOnLine(vec2 p0, vec2 p1, float x)\n\
      {\n\
        float slope = (p0.y - p1.y) / (p0.x - p1.x);\n\
        return slope * (x - p0.x) + p0.y;\n\
      }\n\
      \n\
      czm_material czm_getMaterial(czm_materialInput materialInput)\n\
      {\n\
        czm_material material = czm_getDefaultMaterial(materialInput);\n\
      \n\
        vec2 pos = rotate(v_polylineAngle) * gl_FragCoord.xy;\n\
      \n\
        // Get the relative position within the dash from 0 to 1\n\
        float dashPosition = fract(pos.x / (dashLength * czm_pixelRatio));\n\
        // Figure out the mask index.\n\
        float maskIndex = floor(dashPosition * maskLength);\n\
        // Test the bit mask.\n\
        float maskTest = floor(dashPattern / pow(2.0, maskIndex));\n\
        vec4 fragColor = (mod(maskTest, 2.0) < 1.0) ? gapColor : color;\n\
      \n\
        vec2 st = materialInput.st;\n\
      \n\
      #ifdef GL_OES_standard_derivatives\n\
        float base = 1.0 - abs(fwidth(st.s)) * 10.0 * czm_pixelRatio;\n\
      #else\n\
        float base = 0.975; // 2.5% of the line will be the arrow head\n\
      #endif\n\
      \n\
        vec2 center = vec2(1.0, 0.5);\n\
        float ptOnUpperLine = getPointOnLine(vec2(base, 1.0), center, st.s);\n\
        float ptOnLowerLine = getPointOnLine(vec2(base, 0.0), center, st.s);\n\
      \n\
        float halfWidth = 0.15;\n\
        float s = step(0.5 - halfWidth, st.t);\n\
        s *= 1.0 - step(0.5 + halfWidth, st.t);\n\
        s *= 1.0 - step(base, st.s);\n\
      \n\
        float t = step(base, materialInput.st.s);\n\
        t *= 1.0 - step(ptOnUpperLine, st.t);\n\
        t *= step(ptOnLowerLine, st.t);\n\
      \n\
        // Find the distance from the closest separator (region between two colors)\n\
        float dist;\n\
        if (st.s < base)\n\
        {\n\
            if (fragColor.a < 0.005) {   // matches 0/255 and 1/255\n\
                discard;\n\
            }\n\
            float d1 = abs(st.t - (0.5 - halfWidth));\n\
            float d2 = abs(st.t - (0.5 + halfWidth));\n\
            dist = min(d1, d2);\n\
        }\n\
        else\n\
        {\n\
            fragColor = color;\n\
            float d1 = czm_infinity;\n\
            if (st.t < 0.5 - halfWidth && st.t > 0.5 + halfWidth)\n\
            {\n\
                d1 = abs(st.s - base);\n\
            }\n\
            float d2 = abs(st.t - ptOnUpperLine);\n\
            float d3 = abs(st.t - ptOnLowerLine);\n\
            dist = min(min(d1, d2), d3);\n\
        }\n\
      \n\
        vec4 outsideColor = vec4(0.0);\n\
        vec4 currentColor = mix(outsideColor, fragColor, clamp(s + t, 0.0, 1.0));\n\
        vec4 outColor = czm_antialias(outsideColor, fragColor, currentColor, dist);\n\
      \n\
        outColor = czm_gammaCorrect(outColor);\n\
        material.diffuse = outColor.rgb;\n\
        material.alpha = outColor.a;\n\
        return material;\n\
    }';

        const PolylineDashArrowType = 'PolylineDashArrow';
        Cesium.Material[PolylineDashArrowType] = PolylineDashArrowType;
        Cesium.Material._materialCache.addMaterial(PolylineDashArrowType, {
          strict: true,
          fabric: {
            type: PolylineDashArrowType,
            uniforms: {
              color: Cesium.Color.WHITE,
              gapColor: Cesium.Color.TRANSPARENT,
              dashLength: 16.0,
              dashPattern: 255.0
            },
            source: PolylineDashArrowSource
          },
          translucent: true
        });
        super(options);
      }

      getType() {
        return 'PolylineDashArrow';
      }
    }

    /**
     * @description: 将图片和文字合成新图标使用（参考Cesium源码）
     * @param {*} url：图片地址
     * @param {*} label：文字
     * @param {*} size：画布大小
     * @param {*} fontSize: 字号大小
     * @return {*} 返回canvas
     */
    ufi3d.combineIconAndLabel = (url, label, size, fontSize = 30) => {
      // 创建画布对象
      let canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;

      let ctx = canvas.getContext('2d');

      let promise = new Cesium.Resource.fetchImage(url).then((image) => {
        // 异常判断
        try {
          ctx.drawImage(image, 0, 0);
        } catch (e) {
          console.log(e);
        }

        // 渲染字体
        // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
        ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
        ctx.font = `bold ${fontSize}px Microsoft YaHei`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(label, size / 2, size / 2);

        return canvas;
      });
      return promise;
    }



    // 暴露sdk全局变量
    if (typeof noGlobal === "undefined") {
      window.ufi3d = window.$ = ufi3d;
    }

    return ufi3d;
  })


export default ufi3d